import React, {useCallback, useMemo} from "react";
import {
  sendAnalyticsDataOnRegionChange,
  sendAnalyticsDataOnSearch,
} from "@components/Region/utils/handleSendAnalyticsData";
import {useTranslation} from "@i18n/client";
import {ButtonVariant} from "@components/components/Tree/types";
import {TreeView} from "@components/components/Tree";
import {TreeItem} from "@components/components/Tree/ComplexTreeWrapper";
import {Url} from "next/dist/shared/lib/router/router";
import {LinkProps} from "next/link";
import {RegionSlug} from "../../../store/types";
import {getStateCodeFromRegionSlug} from "../../../utils/stateUtils";
import {setUserSelectedLocation} from "../../../utils/browser-storage/userSelectedLocation";
import {useCurrentRoute} from "../../../hooks/useCurrentRoute";
import {compact, sortBy} from "lodash";

const isCARegion = (slug: string) => getStateCodeFromRegionSlug(slug) === "CA";

const buildRegionTree =
  (regions: SelectableRegion[]) =>
  (searchTerm: string): TreeItem<string> => {
    const filteredRegions = regions.filter(({name}) =>
      name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const filteredCARegions: TreeItem<string>[] = filteredRegions
      .filter(region => isCARegion(region.slug))
      .map(region => ({
        id: region.slug,
        data: region.name,
      }));

    const californiaParentItem: TreeItem<string> | undefined =
      filteredCARegions.length > 0
        ? {
            id: "ca",
            data: "California",
            children: filteredCARegions,
          }
        : undefined;

    const otherRegions = filteredRegions.filter(({slug}) => !isCARegion(slug));

    return {
      id: "root",
      children: sortBy(
        compact([
          californiaParentItem,
          ...otherRegions.map(region => ({
            id: region.slug,
            data: region.name,
          })),
        ]),
        "data",
      ),
      data: "Root",
    };
  };

export type SelectableRegion = {slug: string; name: string};

const RegionTreeSelector = ({
  buttonVariant = "primary",
  getAs,
  getHref,
  onSelect,
  regions,
  selectedRegionSlug,
  shallow,
}: {
  buttonVariant?: ButtonVariant;
  getHref?: (item: TreeItem<string>) => Url | undefined;
  getAs?: (item: TreeItem<string>) => LinkProps["as"] | undefined;
  onSelect?: (slug: RegionSlug) => void;
  regions: SelectableRegion[];
  selectedRegionSlug: string;
  shallow?: LinkProps["shallow"];
}) => {
  const i18n = useTranslation();

  const expandedItems = useMemo(
    () => (getStateCodeFromRegionSlug(selectedRegionSlug) === "CA" ? ["ca"] : []),
    [selectedRegionSlug],
  );
  const selectedRegion = useMemo(
    () => regions.find(({slug}) => slug === selectedRegionSlug) || regions[0],
    [regions, selectedRegionSlug],
  );
  const selected = useMemo(
    () => (selectedRegion ? {data: selectedRegion.name, id: selectedRegion.slug} : null),
    [selectedRegion],
  );

  const currentRoute = useCurrentRoute();
  const handleSelect = useCallback(
    (slug: string | number) => {
      if (typeof slug === "string") {
        setUserSelectedLocation({regionSlug: slug as RegionSlug});
        if (onSelect) onSelect(slug as RegionSlug);
        sendAnalyticsDataOnRegionChange(slug, currentRoute);
      }
    },
    [currentRoute, onSelect],
  );

  const buildTree = useMemo(() => buildRegionTree(regions), [regions]);

  return selected ? (
    <TreeView
      buttonVariant={buttonVariant}
      selectorName={i18n.t("Region")}
      searchLabel={i18n.t("Search by region name")}
      searchPlaceholder={i18n.t("Search regions")}
      selected={selected}
      initialExpandedItems={expandedItems}
      handleSelect={handleSelect}
      handleSearch={sendAnalyticsDataOnSearch}
      buildTree={buildTree}
      getHref={getHref}
      getAs={getAs}
      shallow={shallow}
    />
  ) : null;
};

export default React.memo(RegionTreeSelector);
